import HeaderLogo from "../components/headers/HeaderLogo"
import HomeForShopsSection from "../components/home/HomeForShopsSection"
import HomeTutorialSection from "../components/home/HomeTutorialSection"
import "../assets/css/home.css"
import Footer from "../components/common/Footer"
import HomeShopsList from "../components/home/HomeShopsList"
import HomeMainContainer from "../components/home/HomeMainContainer"

export default function Home() {
  return (
    <>
      <HeaderLogo />
      <HomeMainContainer />
      <HomeShopsList />
      <HomeForShopsSection />
      <HomeTutorialSection />
      <Footer />
    </>
  )
}
