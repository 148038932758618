import Footer from "../components/common/Footer"
import HeaderLogo from "../components/headers/HeaderLogo"
import HomeMainContainer from "../components/home/HomeMainContainer"
import ShopsList from "../components/shops/ShopsList"

export default function ShopsPage() {
  return (
    <>
      <HeaderLogo />
      <HomeMainContainer displayButtons={false} />
      <ShopsList />
      <Footer />
    </>
  )
}
