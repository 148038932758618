export default function HomeTutorialSection() {
  return (
    <div className="home-subsection-rounded-container">
      <div className="row pt-4 text-center">
        <h1 style={{ fontSize: "56px" }} className="mt-4">
          Vuoi comprare una gift card?
        </h1>
      </div>
      <div className="row">
        <div className="d-flex justify-content-center pt-4 flex-column flex-md-row">
          <div className="col-12 col-md-2 text-center pt-4 pb-4">
            <img src={`${process.env.PUBLIC_URL}/img/home/home_step_1.png`} alt="Step 1" />
            <h5 style={{ fontSize: "24px" }} className="mt-4">
              Scegli il suo valore!
            </h5>
            <p>
              Puoi scegliere una gift card del valore che preferisci: potr&agrave; essere utilizzata in pi&ugrave; di un
              negozio fino all&apos;esaurimento del credito!
            </p>
          </div>
          <div className="col-1 d-none d-md-block"></div>
          <div className="col-12 col-md-2 text-center pt-4 pb-4">
            <img src={`${process.env.PUBLIC_URL}/img/home/home_step_2.png`} alt="Step 2" />
            <h5 style={{ fontSize: "24px" }} className="mt-4">
              Un regalo sempre gradito
            </h5>
            <p>
              Inserisci il nome e l&apos;indirizzo email della persona a cui vuoi fare un regalo. La gift card verrà
              inviata tramite mail direttamente al tuo destinatario con un messaggio personalizzato da parte tua.
            </p>
          </div>
          <div className="col-1 d-none d-md-block"></div>
          <div className="col-12 col-md-2 text-center pt-4 pb-4">
            <img src={`${process.env.PUBLIC_URL}/img/home/home_step_3.png`} alt="Step 3" />
            <h5 style={{ fontSize: "24px" }} className="mt-4">
              Supporta il commercio locale
            </h5>
            <p>
              Le Gift Card di Buoni Sul Serio, possono essere spese in tutte le attivit&agrave; del distretto di Clusone
              aderenti all&apos;iniziativa. Le trovi tutte sul sito o nell&apos;app!
            </p>
          </div>
        </div>
      </div>
      <div className="row pb-4">
        <div className="col text-center">
          <button className="btn btn-violet">Scopri le attivit&agrave;</button>
        </div>
      </div>
    </div>
  )
}
