import RegistrationRequestForm from "../components/form/RegistrationRequestForm"
import RegistrationPageHeader from "../components/headers/RegistrationPageHeader"
import "../assets/css/registration.css"

export default function RegistrationPage() {
  return (
    <div
      style={{ backgroundColor: "#FFF", maxWidth: "850px" }}
      className="mt-0 mb-0 mt-md-4 mb-md-4 rounded-4 mx-auto p-4"
    >
      <RegistrationPageHeader />
      <RegistrationRequestForm />
    </div>
  )
}
