import { Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import MaintenancePage from "./pages/MaintenancePage"
import RegistrationPage from "./pages/RegistrationPage"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-loading-skeleton/dist/skeleton.css"
import ShopsPage from "./pages/ShopsPage"
import "bootstrap/dist/js/bootstrap.bundle"

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/shops" element={<ShopsPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="*" element={<MaintenancePage />} />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  )
}

export default App
