import QueryString from "qs"
import { GetShopsRequestDto, GetShopsResultDto } from "../models/api/shopApiTypes"
import { api } from "./clientApi"

export function getShops(payload?: GetShopsRequestDto) {
  return new Promise<GetShopsResultDto>((resolve, reject) => {
    api
      .get("/Shops", {
        params: payload,
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: "repeat" }),
      })
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}
