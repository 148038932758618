import { Carousel, CarouselItem } from "react-bootstrap"

interface ShopCardImageCarouselProps {
  img: string[]
}

export default function ShopCardImageCarousel({ img }: ShopCardImageCarouselProps) {
  return img.length > 1 ? (
    <Carousel className="shop-card-img-container" interval={null}>
      {img.map((imgSrc, index) => (
        <CarouselItem className="w-100 h-100" key={index}>
          <img src={imgSrc} className="shop-card-img object-fit-cover" alt="Immagine negozio" />
        </CarouselItem>
      ))}
    </Carousel>
  ) : img.length > 0 ? (
    <div className="shop-card-img-container">
      <img src={img[0]} className="w-100 h-100 object-fit-cover" alt="Immagine negozio" />
    </div>
  ) : (
    <div className="shop-card-img-container">
      <img
        src={`${process.env.PUBLIC_URL}/img/shop/noimg.png`}
        className="w-100 h-100 object-fit-cover"
        alt="Immagine negozio"
      />
    </div>
  )
}
