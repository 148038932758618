import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { TownDto } from "../../models/api/districtsApiTypes"
import { getDistricts } from "../../api/districtsApi"
import { CategoryDto } from "../../models/api/categoriesApiTypes"
import { getCategories } from "../../api/categoriesApi"
import { CreateRegistrationRequestDto } from "../../models/api/registrationRequestsApiTypes"
import { createRegistrationRequest } from "../../api/registrationApi"
import { toast } from "react-toastify"
import cn from "classnames"
import { useNavigate } from "react-router-dom"

export default function RegistrationRequestForm() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateRegistrationRequestDto>()
  const [towns, setTowns] = useState<TownDto[]>([])
  const [categories, setCategories] = useState<CategoryDto[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    getDistricts().then((districts) => {
      setTowns(districts.map((d) => d.towns).flat())
    })
    getCategories().then((c) => setCategories(c))
  }, [])

  const onSubmit = async (data: CreateRegistrationRequestDto) => {
    createRegistrationRequest(data)
      .then(() => {
        toast.success("Registrazione inviata")
        navigate("/home")
      })
      .catch(() => toast.error("Errore nella registrazione"))
  }

  return (
    <div style={{ maxWidth: "576px" }} className="mx-auto mt-0 mb-0 mt-sm-4 mb-sm-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="mb-2">Informazioni personali</h4>
        <div className="row">
          <div className="col-12 col-sm-6 mb-4">
            <label htmlFor="ownerName" className="form-label">
              Nome
            </label>
            <input
              type="text"
              className={cn("form-control", { "is-invalid": errors.ownerEmail })}
              {...register("ownerName", { required: true })}
            />
            {errors.ownerEmail && <div className="invalid-feedback">Nome titolare richiesto</div>}
          </div>
          <div className="col-12 col-sm-6 mb-4">
            <label htmlFor="ownerSurname" className="form-label">
              Cognome
            </label>
            <input
              type="text"
              className={cn("form-control", { "is-invalid": errors.ownerSurname })}
              {...register("ownerSurname", { required: true })}
            />
            {errors.ownerSurname && <div className="invalid-feedback">Cognome titolare richiesto</div>}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 mb-4">
            <label htmlFor="ownerEmail" className="form-label">
              Indirizzo email personale
            </label>
            <input
              type="email"
              className={cn("form-control", { "is-invalid": errors.ownerEmail })}
              {...register("ownerEmail", {
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Inserisci un indirizzo valido",
                },
              })}
            />
            {errors.ownerPhoneNumber && <div className="invalid-feedback">Inserisci un indirizzo valido</div>}
          </div>
          <div className="col-12 col-sm-6 mb-4">
            <label htmlFor="ownerPhoneNumber" className="form-label">
              Numero telefono titolare
            </label>
            <input
              type="text"
              className={cn("form-control", { "is-invalid": errors.ownerPhoneNumber })}
              {...register("ownerPhoneNumber", { required: true })}
            />
            {errors.ownerPhoneNumber && <div className="invalid-feedback">Numero telefono richiesto</div>}
          </div>
        </div>
        <h4 className="mt-4 mb-2">Informazioni attivit&agrave;</h4>
        <div className="row">
          <div className="col-12 mb-4">
            <label htmlFor="companyName" className="form-label">
              Nome attivit&agrave;
            </label>
            <input
              type="text"
              className={cn("form-control", { "is-invalid": errors.companyName })}
              {...register("companyName", { required: true })}
            />
            {errors.companyName && <div className="invalid-feedback">Nome attivit&agrave; richiesto</div>}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <label htmlFor="shopAddress" className="form-label">
              Indirizzo
            </label>
            <input
              type="text"
              className={cn("form-control", { "is-invalid": errors.shopAddress })}
              {...register("shopAddress", { required: true })}
            />
            {errors.shopAddress && <div className="invalid-feedback">Indirizzo richiesto</div>}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 mb-4">
            <label htmlFor="shopTownId" className="form-label">
              Comune
            </label>
            <select
              className={cn("form-select", { "is-invalid": errors.shopTownId })}
              {...register("shopTownId", {
                validate: { notEmpty: (v) => v !== "" || "Seleziona comune" },
              })}
            >
              <option value=""></option>
              {towns.map((town) => (
                <option value={town.id} key={town.id}>
                  {town.name}
                </option>
              ))}
            </select>
            {errors.shopTownId && <div className="invalid-feedback">Seleziona comune</div>}
          </div>
          <div className="col-12 col-sm-6 mb-4">
            <label htmlFor="categoryId" className="form-label">
              Settore
            </label>
            <select
              className={cn("form-select", { "is-invalid": errors.categoryId })}
              {...register("categoryId", {
                validate: { notEmpty: (v) => v !== "" || "Seleziona settore" },
              })}
            >
              <option value=""></option>
              {categories.map((category) => (
                <option value={category.id} key={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            {errors.categoryId && <div className="invalid-feedback">Seleziona settore</div>}
          </div>
        </div>
        <div className="d-grid gap-2 mb-4">
          <input type="submit" className="btn btn-primary" value="Invia registrazione" />
        </div>
      </form>
    </div>
  )
}
