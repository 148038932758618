import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { TownDto } from "../../models/api/districtsApiTypes"
import { CategoryDto } from "../../models/api/categoriesApiTypes"
import Select, { MultiValue, components, NoticeProps, GroupBase } from "react-select"

interface ShopsFilterProps {
  towns: TownDto[]
  categories: CategoryDto[]
  townsFilter: string[]
  setTownsFilter: React.Dispatch<React.SetStateAction<string[]>>
  categoriesFilter: string[]
  setCategoriesFilter: React.Dispatch<React.SetStateAction<string[]>>
}

export default function ShopsFilter({
  towns,
  categories,
  townsFilter,
  setTownsFilter,
  categoriesFilter,
  setCategoriesFilter,
}: ShopsFilterProps) {
  const [show, setShow] = useState(false)

  const [selectedTowns, setSelectedTowns] = useState<TownDto[]>(towns)
  const [selectedCategories, setSelectedCategories] = useState<CategoryDto[]>(categories)

  const handleClose = () => {
    setShow(false)
    setSelectedTowns(towns.filter((t) => townsFilter.includes(t.id)))
    setSelectedCategories(categories.filter((c) => categoriesFilter.includes(c.id)))
  }
  const handleShow = () => setShow(true)

  // See handleCategoryCheck for documentation
  const handleTownCheck = (items: MultiValue<TownDto>) => {
    setSelectedTowns([...items])
  }

  const handleCategoryCheck = (items: MultiValue<CategoryDto>) => {
    setSelectedCategories([...items])
  }

  const saveFilters = () => {
    setTownsFilter(selectedTowns.map((t) => t.id))
    setCategoriesFilter(selectedCategories.map((c) => c.id))
    setShow(false)
  }

  const NoOptionsMessage: React.ComponentType<NoticeProps<any, true, GroupBase<any>>> = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Nessuna opzione</span>
      </components.NoOptionsMessage>
    )
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h3 className="mt-4">Filtra le attivit&agrave;</h3>
          <div className="row mb-4">
            <div className="col-12 mb-4 mt-2">
              <h5>Comune</h5>
              <Select
                options={towns}
                isMulti={true}
                isSearchable={true}
                name="towns"
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Seleziona citt&agrave;"
                value={selectedTowns}
                onChange={(items) => handleTownCheck(items)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                components={{ NoOptionsMessage }}
              />
            </div>
            <div className="col-12 mb-4 mt-2">
              <h5>Settore</h5>
              <Select
                options={categories}
                isMulti={true}
                isSearchable={true}
                name="categories"
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Seleziona categorie"
                value={selectedCategories}
                onChange={(items) => handleCategoryCheck(items)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                components={{ NoOptionsMessage }}
              />
            </div>
          </div>
          <div className="text-end mb-4">
            <button type="button" className="btn btn-green" onClick={saveFilters}>
              Conferma
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="row mx-auto text-end" style={{ maxWidth: "1440px" }}>
        <div>
          <button type="button" className="btn btn-green-outline" onClick={handleShow}>
            <img src={`${process.env.PUBLIC_URL}/img/icons/filter.svg`} alt="Filtra" /> Filtri
          </button>
        </div>
      </div>
    </>
  )
}
