import { GetDistrictsResultDto } from "../models/api/districtsApiTypes"
import { api } from "./clientApi"

export function getDistricts() {
  return new Promise<GetDistrictsResultDto>((resolve, reject) => {
    api
      .get("/Districts")
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}
