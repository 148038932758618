import { Link } from "react-router-dom"

export default function HeaderLogo() {
  return (
    <header className="header-nav">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/home">
            <img src={`${process.env.PUBLIC_URL}/img/logo_extended.png`} alt="Logo" width="300vw" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <div className="ms-auto">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    Come funziona?
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active" to="/shops">
                    Attività
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
