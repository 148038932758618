import axios from "axios"

// Unauthenticated axios instance
export const api = axios.create({
  baseURL: `${process.env.REACT_APP_BE_URL}/api/app`,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
})
