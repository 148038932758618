export default function MaintenancePage() {
  return (
    <>
      <div style={{ marginTop: "5vh", marginLeft: "5vw" }}>
        <h2 style={{ fontSize: "24px" }}>BuoniSulSerio</h2>
      </div>
      <div className="mx-auto d-flex flex-column">
        <div className="mx-auto mt-4">
          <img src={`${process.env.PUBLIC_URL}/img/maintenance/gift_logo.svg`} alt="Gift logo" />
        </div>
        <div style={{ maxWidth: "680px" }} className="mx-auto text-center mt-4 ps-4 pe-4">
          <h1 style={{ lineHeight: "100%" }} className="maintenance-subtitle">
            La piattaforma per incentivare il commercio locale
          </h1>
        </div>
        <div className="mx-auto text-center mt-4">
          <p style={{ fontSize: "20px" }}>Stiamo lavorando per voi!</p>
        </div>
      </div>
    </>
  )
}
