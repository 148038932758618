import { CreateRegistrationRequestDto } from "../models/api/registrationRequestsApiTypes"
import { api } from "./clientApi"

export function createRegistrationRequest(payload: CreateRegistrationRequestDto) {
  return new Promise<void>((resolve, reject) => {
    api
      .post("/RegistrationRequests", payload)
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}
