import cn from "classnames"

interface ShopsPaginationProps {
  page: number
  setPage: (page: number) => void
  totalPages: number
}

export default function ShopsPagination({ page, setPage, totalPages }: ShopsPaginationProps) {
  return (
    <nav aria-label="Page navigation" className="mt-4">
      <ul className="pagination justify-content-center ">
        <li className={cn("page-item", { disabled: page <= 1 })}>
          <a className="page-link" href="#" aria-label="Previous" onClick={() => setPage(page - 1)}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {Array.from({ length: totalPages }, (_, index) => (
          <li key={index} className={cn("page-item", { active: page === index + 1 })} aria-current="page">
            <a className="page-link" href="#" onClick={() => setPage(index + 1)}>
              {index + 1}
            </a>
          </li>
        ))}
        <li className={cn("page-item", { disabled: page >= totalPages })}>
          <a className="page-link" href="#" aria-label="Next" onClick={() => setPage(page + 1)}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}
