import ShopCardSkeleton from "../common/ShopCardSkeleton"

export default function HomeShopCardsSkeleton() {
  return (
    <>
      <div className="col-6 col-lg-4">
        <ShopCardSkeleton />
      </div>
      <div className="col-6 col-lg-4">
        <ShopCardSkeleton />
      </div>
      <div className="col-6 col-lg-4 d-none d-lg-block">
        <ShopCardSkeleton />
      </div>
    </>
  )
}
