import { Link } from "react-router-dom"

export default function Footer() {
  return (
    <footer>
      <div className="row p-4">
        <div className="col d-flex">
          <div className="align-self-center" style={{ marginLeft: "100px" }}>
            &copy;2024 BuoniSulSerio
          </div>
        </div>
      </div>
      <div className="row">
        <div style={{ paddingLeft: "100px", paddingRight: "100px" }}>
          <hr />
        </div>
      </div>
      <div className="row p-4">
        <div className="col-12 col-md-6 text-center text-md-start">
          <div className="d-flex">
            <div className="align-self-center me-4" style={{ marginLeft: "100px", marginRight: "100px" }}>
              <Link to="/home">Home</Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 mt-4 mt-md-0 text-center text-md-end">
          <div style={{ marginLeft: "100px", marginRight: "100px" }}>
            <div className="d-inline-flex">
              <div className="me-4">
                <img src={`${process.env.PUBLIC_URL}/img/footer/icons/facebook.png`} alt="Facebook" />
              </div>
              <div className="me-4">
                <img src={`${process.env.PUBLIC_URL}/img/footer/icons/linkedin.png`} alt="LinkedIn" />
              </div>
              <div className="me-4">
                <img src={`${process.env.PUBLIC_URL}/img/footer/icons/twitter.png`} alt="Twitter" />
              </div>
              <div className="me-4">
                <img src={`${process.env.PUBLIC_URL}/img/footer/icons/youtube.png`} alt="Youtube" />
              </div>
              <div>
                <img src={`${process.env.PUBLIC_URL}/img/footer/icons/instagram.png`} alt="Instagram" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
