import { GetCategoriesResultDto } from "../models/api/categoriesApiTypes"
import { api } from "./clientApi"

export function getCategories() {
  return new Promise<GetCategoriesResultDto>((resolve, reject) => {
    api
      .get("/Categories")
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}
