import Skeleton from "react-loading-skeleton"

export default function ShopCardSkeleton() {
  return (
    <div className="shop-card mx-auto">
      <div className="text-center">
        <Skeleton className="shop-card-img-skeleton mt-3" />
      </div>
      <p className="shop-card-title ms-4 me-4 mt-3 mb-0">
        <Skeleton />
      </p>
      <p className="shop-card-description ms-4 me-4">
        <Skeleton />
      </p>
      <span className="shop-card-category ms-4">
        <Skeleton width="25%" />
      </span>
    </div>
  )
}
