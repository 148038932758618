interface TextBoxWithIconProps {
  iconPath: string
  title: string
  text: string
}

export default function TextBoxWithIcon({ iconPath, title, text }: Readonly<TextBoxWithIconProps>) {
  return (
    <div className="d-flex flex-row">
      {iconPath !== "" ? (
        <div className="pe-2">
          <img src={iconPath} alt="icon" />
        </div>
      ) : null}
      <div>
        <h6 className="textbox-with-icon-title">{title}</h6>
        <p className="textbox-with-icon-description">{text}</p>
      </div>
    </div>
  )
}
