export default function RegistrationPageHeader() {
  return (
    <div className="d-md-flex justify-content-center align-items-center" style={{ paddingTop: "32px" }}>
      <div className="text-center text-md-start">
        <img
          src={`${process.env.PUBLIC_URL}/img/registration_header.svg`}
          alt="Registration logo"
          id="registration_logo"
          style={{ marginLeft: "32px", marginRight: "32px" }}
        />
      </div>
      <div className="text-center text-md-start">
        <h2>
          Registra la tua attività
          <br />a BuoniSulSerio
        </h2>
      </div>
    </div>
  )
}
